import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading3 from '../../ui/Heading3';
import Heading2 from '../../ui/Heading2';
import { Link } from 'gatsby';
import { ReactComponent as ArrowRightIco } from '../../images/icons/arrow-right.svg';

const Container = styled.div`
  max-width: 1026px;
  margin: auto;
  margin-bottom: 120px;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 0 20px;
  }
`;

const Positions = styled.ul`
  list-style: none;
  margin: 0;
`;

const Position = styled.li`
  display: flex;
  padding: 32px 40px;
  border: 2px solid ${COLORS.LIGHT_GRAY};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 24px 20px;
  }
`;

const Category = styled(Heading3)`
  margin: 0;
`;

const LinkTo = styled(Link)`
  font-weight: 600;
  letter-spacing: 0.5px;
`;

interface Props {
  positions: {
    name: string;
    linkTo?: string;
    externalTo?: string;
    linkCopy: string;
  }[];
}

const OpenPositions = ({ positions }: Props) => (
  <Container>
    <Heading2
      css={css`
        margin-bottom: 60px;
      `}
    >
      Open Positions
    </Heading2>
    <Positions>
      {positions.map(position => (
        <Position>
          <Category>{position.name}</Category>
          {position.linkTo && (
            <LinkTo to={position.linkTo}>
              {position.linkCopy}{' '}
              <ArrowRightIco
                width='14'
                height='11'
                css={css`
                  fill: ${COLORS.ORANGE};
                `}
              />
            </LinkTo>
          )}

          {position.externalTo && (
            <a
              style={{ fontWeight: 600, letterSpacing: '.5px' }}
              href={position.externalTo}
              target='_blank'
            >
              {position.linkCopy}{' '}
              <ArrowRightIco
                width='14'
                height='11'
                css={css`
                  fill: ${COLORS.ORANGE};
                `}
              />
            </a>
          )}
        </Position>
      ))}
    </Positions>
  </Container>
);

export default OpenPositions;
