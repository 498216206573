import React from 'react';
import styled from '@emotion/styled';
import Img, { FixedObject } from 'gatsby-image';
import ParagraphSmall from '../ui/ParagraphSmall';
import { Name } from '../ui/TypographyStyles';

const CardUser = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CardAvatar = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 16px;
`;

interface Props {
  name: string;
  position: string;
  fixedImage?: FixedObject;
  className?: string;
}

const EmployeeTestimonialAvatar = ({
  fixedImage,
  position,
  name,
  className,
}: Props) => (
  <CardUser className={className}>
    {fixedImage && (
      <CardAvatar>
        <Img fixed={fixedImage} />
      </CardAvatar>
    )}

    <span>
      <Name>{name}</Name>
      <br />
      <ParagraphSmall style={{ margin: 0 }}>{position}</ParagraphSmall>
    </span>
  </CardUser>
);

export default EmployeeTestimonialAvatar;
