import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { Link } from 'gatsby';
import { NAV_HEIGHTS } from '../header/types';

const Container = styled.nav`
  background: ${COLORS.WHITE};
  height: 80px;
  border-top: 1px solid ${COLORS.LIGHT_GRAY};
  border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
  position: sticky;
  top: ${NAV_HEIGHTS.DESKTOP};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${BREAKPOINTS.TABLET} {
    height: 69px;
    top: ${NAV_HEIGHTS.TABLET};
  }
`;

const Actions = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;

  @media ${BREAKPOINTS.MOBILE} {
    padding-left: 300px;
  }
`;

const Action = styled.li`
  margin: 0;
  padding: 0 16px;
  font-size: 20px;
  flex-shrink: 0;
`;

const ActionLink = styled(Link)`
  color: ${COLORS.BLACK};
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.5px;

  &:hover {
    color: ${COLORS.DARK_GRAY};
  }
`;

const activeActionStyle = {
  color: COLORS.DARK_GRAY,
};

const CareersNavigation = () => (
  <Container>
    <Actions>
      <Action>
        <ActionLink activeStyle={activeActionStyle} to='/careers/'>
          About Small Door
        </ActionLink>
      </Action>
      <Action>
        <ActionLink activeStyle={activeActionStyle} to='/careers/values/'>
          Values
        </ActionLink>
      </Action>
      <Action>
        <ActionLink
          activeStyle={activeActionStyle}
          to='/careers/veterinarians/'
        >
          Veterinarians
        </ActionLink>
      </Action>
      <Action>
        <ActionLink activeStyle={activeActionStyle} to='/careers/nurses/'>
          Nurses
        </ActionLink>
      </Action>
      <Action>
        <ActionLink activeStyle={activeActionStyle} to='/careers/headquarters/'>
          Headquarters
        </ActionLink>
      </Action>
      <Action>
        <ActionLink
          activeStyle={activeActionStyle}
          to='https://boards.greenhouse.io/smalldoor'
        >
          Open Positions
        </ActionLink>
      </Action>
    </Actions>
  </Container>
);

export default CareersNavigation;
